@font-face {
  font-family: "RobotoCondesedRegular";
  src: url("fonts/RobotoCondensed-Regular.ttf");
}
html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
}
.react-player {
  border: 2px solid #0478573a;
  border-radius: 5px;
}
img {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
}
